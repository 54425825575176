.button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 18rem;
  margin: auto;
  border: 2px solid var(--color-white);
  background: transparent;
  font-size: 1.8rem;
  overflow: hidden !important;
  z-index: 2;
  background-color: transparent;
  text-decoration: none;
  font-family: var(--font-secondary);
  padding: 0.5rem 4rem;
  color: var(--color-white);
  transition: color 1s ease;
}

.button_layer {
  position: absolute;
  width: 140%;
  height: 110%;
  left: -160%;
  top: 0;
  background-color: var(--color-white);
  z-index: -1;
  transition: all 1s cubic-bezier(0.65, 0.55, 0.03, 0.98);
  transform: skew(307deg);
}

.button:hover .button_layer {
  left: -19%;
}
.button:hover {
  color: var(--color-nav-primary);
}

@media screen and (max-width: 54.15rem) {
  .button {
    font-size: 1.2rem;
    padding: 0.4rem 0rem;
    max-width: 10rem;
  }
}
