.nav {
  width: 9rem;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.onTop {
  z-index: 10;
}

.logo {
  width: 8rem;
  padding-left: 1rem;
}

.logo img {
  width: 4rem;
}

.logo a {
  color: var(--color-primary);
  font-size: 2.5rem;
  font-weight: 600;
  font-family: 'Fjalla One', sans-serif;
  text-decoration: none;
  transition: all 0.6s ease;
}

.logo h1 {
  margin: 0;
}

.logo .hamburger_h1 {
  font-size: 2rem;
}

.logo a:hover {
  color: var(--color-white);
}

.navbar-brand {
  color: var(--color-primary);
  font-size: 2.5rem;
  font-weight: 600;
  font-family: 'Fjalla One', sans-serif;
  text-decoration: none;
  transition: all 0.6s ease;
}

.navbar-brand:hover {
  color: var(--color-white);
}

/* HAMBURGER */
.sticky_hamburger {
  position: sticky !important;
  top: 0;
  z-index: 50;
}

.logo_navbar {
  font-size: 3rem !important;
  color: var(--color-white) !important;
}

.text_navbar {
  font-size: 2.5rem !important;
  color: var(--color-white) !important;
  font-family: 'Fjalla One', sans-serif !important;
}

.text_navbar_hamburger {
  text-align: end;
}

.text_navbar_hamburger:hover {
  color: var(--color-primary) !important;
}

.align_navbar {
  text-align: end;
}

.span_navbar {
  background-color: rgba(255, 255, 255, 0.7) !important;
  color: var(--color-white) !important;
}

.hide_hamburger {
  display: none !important;
  font-weight: 600;
  font-size: 2.5rem;
  background-color: var(--color-nav-primary) !important;
}

.hamburger_cont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  max-height: 60px;
  padding: 5px 0px;
  margin: 5px;
  border-radius: 0.8rem;
  background: #bcbcbc;
}

@media screen and (max-width: 850px) {
  .nav {
    display: none !important;
  }

  .hide_hamburger {
    display: block !important;
  }
}
