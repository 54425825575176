.project_container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  color: #f4f4f8;
  text-align: center;
  margin-top: 2rem;
}

.projects_inner {
  max-width: 300px;
  background-color: var(--color-nav-secondary);
  padding: 0;
  margin-bottom: 2rem;
  overflow: hidden;
  transition: all 0.4s ease;
  position: relative;
  cursor: pointer;
}

.projects_inner figure {
  margin-bottom: 0;
}

.projects_inner img {
  width: 300px;
  height: 300px;
}

.projects_inner:hover {
  /* box-shadow: 0 0px 1rem 2px rgba(0, 228, 245, 0.6); */
}

.projects_inner figcaption {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 3rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: absolute;
  top: -300px;
  background-color: var(--color-bg-project);
  transition: all 0.7s ease;
}

.active {
  top: 0px !important;
}

.projects_inner figcaption h4 {
  color: var(--color-primary);
  margin-bottom: 1rem;
  font-size: 1.2rem !important;
}

.projects_inner figcaption p {
  font-size: 0.9rem;
  color: var(--color-white);
}

.projects_inner figcaption .used_skills {
  position: absolute;
  bottom: 0;
}

.used_skills p {
  color: #00e4f5;
}

.projects_inner figcaption .links {
  position: absolute;
  bottom: 3rem;
  color: var(--color-primary);
}

.projects_inner figcaption .links {
  display: flex;
  gap: 1rem;
}

.links i {
  color: var(--color-primary);
  font-size: 1.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1.5px solid var(--color-primary);
  transition: all 0.5s ease;
}

.links i:hover {
  color: var(--color-nav-primary);
  background-color: var(--color-primary);
}
