.active {
  color: var(--color-primary) !important;
}

.active::before {
  border: 4px solid var(--color-white);
  width: 1rem;
  height: 1rem;
}

.nav-items {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style: none;
  text-align: start;
  background: transparent;
  padding: 0;
  width: 8rem;
  padding-left: 1rem;
}

.nav-items li a {
  text-decoration: none;
  color: var(--color-white);
  font-size: 1.3rem;
  font-weight: 500;
  transition: color 0.4s ease;
  padding-right: 1rem;
  font-family: 'Fjalla One', sans-serif !important;
}

.nav-items li a:hover {
  color: var(--color-primary);
}
