input[type='checkbox'] {
  -webkit-appearance: none;
  visibility: hidden;
  display: none;
}

.switch_container {
  position: absolute;
  top: 6rem;
  right: 1.5rem;
  z-index: 11;
}

.check {
  position: relative;
  display: block;
  width: 40px;
  height: 20px;
  background: #7eb2ff;
  cursor: pointer;
  border-radius: 20px;
  overflow: hidden;
  transition: ease-in 0.5s;
}

input[type='checkbox']:checked ~ .check {
  background: #7eb2ff;
}

.check:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  background-color: var(--color-nav-primary);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transition: all 0.5s ease;
}

input[type='checkbox']:checked ~ .check:before {
  transform: translateX(20px);
  background: var(--color-white);
}

input[type='checkbox']:checked ~ .check:after {
  transform: translateX(0px);
}
