.container_main {
  width: 100vw;
  height: auto;
  background-color: var(--color-bg-primary);
}

.right_content {
  margin-left: 9.5rem;
}

.loading_page {
  color: var(--color-primary);
}

.copyright {
  text-align: center;
  color: var(--color-primary);
  padding: 2rem 0;
  font-weight: 600;
  font-size: 0.8rem;
}

/* Switch Bar */
.fa-sun {
  position: absolute;
  top: 4.4px;
  right: 4.3px;
  color: var(--color-nav-primary);
  font-size: 0.7rem;
  transition: all 0.6s ease;
}

.fa-moon {
  position: absolute;
  top: 4.5px;
  left: 4.5px;
  color: var(--color-white);
  font-size: 0.7rem;
  transition: all 0.6s ease;
}

/* Projects description cancel Icon */
.fa-xmark {
  color: var(--color-primary);
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.4s ease;
  padding: 0.5rem;
}

.fa-xmark:hover {
  color: var(--color-white);
}

#id .social-link a i {
  font-size: 2rem !important;
}

@media screen and (max-width: 850px) {
  .right_content {
    margin-left: 0rem !important;
  }
}

/* Fade in */
.notShow {
  opacity: 0;
  transition: all 1s;
  transition-delay: 300ms;
}

.showContent {
  opacity: 1;
}

@media (prefers-reduced-motion) {
  .notShow {
    transition: none;
  }
}
