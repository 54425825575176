.about {
  display: block;
  width: 100%;
  padding: 3.5rem 2rem;
  height: auto;
}
.about h3 {
  margin: 0;
}

.about_container {
  display: flex;
  flex-direction: column;
  max-width: 60rem;
  text-transform: initial;
  font-size: 1.1rem !important;
  line-height: 1.5;
  font-weight: 500;
}

.about_me {
  margin-top: 2rem;
}

.about_me p + p {
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 34.3rem) {
  .about p {
    font-size: 0.8rem !important;
    margin: 0rem auto 2rem auto;
  }

  .about {
    padding: 3.5rem 1rem;
  }

  .about_me {
    margin-top: 0rem;
  }

  .about h3 {
    padding: 1rem;
  }

  .about_container {
    padding: 1rem;
  }
}

/* EDUCATION */
.education_title {
  margin-left: 1.8rem;
}

.education_container {
  margin-top: 3rem;
}

.education {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0.5rem 0rem;
  height: auto;
}

.education_inner {
  max-width: 250px;
  padding: 1rem;
  overflow: hidden;
  margin: 0 0.8rem;
}

.education_inner h5 {
  color: var(--color-primary);
  font-size: 1rem;
}

.education_inner p {
  font-size: 0.9rem;
  margin: 0.3rem 0;
}

.employment_inner h5 + p {
  color: var(--color-primary);
  font-weight: 500;
}

.education_inner small {
  font-size: 0.7rem;
}
