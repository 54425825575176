/* sidebar Nav */

.navBar {
  position: relative;
}

.menuNav {
  overflow-y: scroll;
  list-style: none;
  position: fixed;
  top: 5.3rem;
  background: var(--color-nav-hamburger-primary);
  left: -2rem;
  bottom: 0;
  height: 100vh;
  width: 0;
  overflow: hidden;
  max-width: 100vw;
  z-index: 9;
  transition: all 0.3s ease;
}

.menuNav .margin-box {
  margin-top: 2rem;
}

.menuNav.showMenu {
  width: 100%;
  left: 0;
}

.menuNav a {
  display: block;
  padding: 10px 40px;
  text-decoration: none;
  color: #3fffd2;
  text-transform: uppercase;
  font-weight: bold;
}

.menuNav li:first-child {
  margin-top: 7rem;
}

/*  */

.btn {
  transform: translate(-50%, -50%);
  width: 80px;
  cursor: pointer;
  transform: scale(0.5);
}

.btn span {
  display: block;
  width: 100%;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  height: 8px;
  background: #fff;
  transition: all 0.3s;
  position: relative;
}

.btn > span + span {
  margin-top: 14px;
}

.active span:nth-child(1) {
  animation: ease 0.4s top forwards;
}

.not-active span:nth-child(1) {
  animation: ease 0.4s top-2 forwards;
}

.active span:nth-child(2) {
  animation: ease 0.4s scaled forwards;
}

.not-active span:nth-child(2) {
  animation: ease 0.4s scaled-2 forwards;
}

.active span:nth-child(3) {
  animation: ease 0.4s bottom forwards;
}

.not-active span:nth-child(3) {
  animation: ease 0.4s bottom-2 forwards;
}

@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 22px;
    transform: rotate(0);
  }
  100% {
    top: 22px;
    transform: rotate(45deg);
  }
}

@keyframes top-2 {
  0% {
    top: 22px;
    transform: rotate(45deg);
  }
  50% {
    top: 22px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 22px;
    transform: rotate(0);
  }
  100% {
    bottom: 22px;
    transform: rotate(135deg);
  }
}

@keyframes bottom-2 {
  0% {
    bottom: 22px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 22px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
