.project {
  display: block;
  width: 100%;
  height: auto;
  padding: 3.5rem 2rem;
}

.loading_page {
  color: var(--color-primary);
}
