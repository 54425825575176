.skills {
  display: block;
  width: 100%;
  padding: 3.5rem 2rem;
}

.skills_main_container {
  margin-top: 2rem;
}

.skills_inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.2rem;
  min-width: 15.625rem;
  max-width: 44rem;
}

.skills_each {
  position: relative;
  height: 4.5rem;
  width: 4.2rem;
}

/* Container Cover for Hover */
.skills_cover {
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: transparent;
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 0.5rem;
  transition: all 0.6s ease;
}

.skills_cover:hover {
  box-shadow: 0px 0px 15px #7eb2ff;
  -webkit-box-shadow: 0px 0px 15px #7eb2ff;
  -moz-box-shadow: 0px 0px 15px #7eb2ff;
}

/* Skill Title */
.skill_name {
  color: var(--color-white);
  position: absolute;
  bottom: -0.6rem;
  text-align: center;
  width: 100%;
}

.skill_name p {
  position: relative;
  /* left: 0.7rem; */
  text-align: center;
  font-size: 0.7rem;
  color: var(--color-white);
  font-weight: 600;
}

/* Skill Icon */
.skill_img {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  padding-bottom: 1.2rem;
  border-radius: 0.5rem;
  background-color: var(--color-nav-primary);
}

.skill_img img {
  position: relative;
  top: -0.5rem;
  height: 2.3rem;
}

/* Smaller Screen Sizes */
@media screen and (max-width: 37.5rem) {
  .skills_inner {
    justify-content: center;
  }

  .skills {
    padding: 2rem 2rem 1rem 2rem;
  }
}
