.home_container {
  width: 100%;
  min-height: 100vh;
}

.home_container .btn_home {
  display: block !important;
  position: relative;
  bottom: 5rem;
  margin-top: 2rem;
}

.home_container_inner {
  width: 100%;
  min-height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.header_text {
  order: 1;
  text-align: end;
}

.header_text h1 {
  font-size: 5rem;
  padding: 0 !important;
  color: var(--color-white);
  transition: 0.6s ease;
}

.header_text h2 {
  font-size: 5rem;
  color: var(--color-primary);
}

.header_img_container {
  order: 2;
}

.header_img {
  background-image: url(../UI/img/dgkn.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 15rem;
  height: 15rem;
  border: 0.6rem solid var(--color-primary);
  border-radius: 7.5rem;
}

.home_container {
  text-align: center;
}

@media screen and (max-width: 54.15rem) {
  .home_container_inner {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .header_text {
    order: 2;
    max-width: 100%;
    text-align: center;
  }

  .header_img_container {
    order: 1;
  }

  .header_img_container {
    height: 40%;
    margin-bottom: 3rem;
  }

  .header_text {
    height: 50%;
  }

  .header_text h1 {
    font-size: 3rem;
  }

  .header_text h2 {
    font-size: 3rem;
  }
}
