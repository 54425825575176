.social-links {
  display: flex;
  gap: 1rem;
}

.social-links a {
  font-size: 0rem !important;
}

.social-link .fa-brands {
  font-size: 1.1rem;
}

.social-link a {
  list-style: none;
  text-align: end;
  color: var(--color-primary);
  transition: color 0.4s ease;
}

.social-link a:hover {
  color: var(--color-white);
}
