.services_section h3 {
  margin: 0;
}

.services_section h4 {
  color: var(--color-primary);
}

.services_container {
  display: flex;
  flex-direction: column;
  max-width: 60rem;
  text-transform: initial;
  font-size: 1.1rem !important;
  line-height: 1.5;
  font-weight: 500;
}

.services_me {
  margin-top: 2rem;
}

.services_image {
}

.services_web {
  order: 2;
}

.services_web2 {
  order: 1;
}

.services_web_image,
.services_web_image2 {
  display: flex;
  align-items: center;
}

.services_web_image {
  order: 1;
}

.services_web_image2 {
  order: 2;
}

.services_me p + p {
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 992px) {
  .services_image {
    width: 15rem;
  }
}

@media screen and (max-width: 991px) {
  .services_web_image2 {
    order: 1;
  }

  .services_web2 {
    order: 2;
  }
}

@media screen and (max-width: 34.3rem) {
  .services_section p {
    font-size: 0.8rem !important;
    margin: 0rem auto 2rem auto;
  }

  .services_section {
    padding: 3.5rem 0rem;
  }

  .services_me {
    margin-top: 0rem;
  }

  .services_section h3 {
    padding: 0rem;
  }

  .services_container {
    padding: 0rem;
  }
}
