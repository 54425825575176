.contact {
  display: block;
  width: 100%;
  padding: 3.5rem 2rem;
}

.contact_detail {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0;
  margin-top: 2.5rem;
}

.contact_detail {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  font-size: 1.3rem;
  margin-top: 3rem;
  text-transform: initial;
}

.contact h3 {
  margin: 0;
}

.contact_detail strong {
  font-size: 1rem;
  display: block;
  text-transform: none;
  padding-left: 2.5rem;
  color: var(--color-primary);
  font-weight: 600;
}

.resume {
  font-size: 1rem;
  text-transform: none;
  padding-left: 2.5rem;
  color: var(--color-primary);
  font-weight: 600;
  text-decoration: none;
  transition: all 0.4s ease;
}

.resume_section small {
  font-size: 1rem;
}

.resume:hover {
  color: var(--color-white);
}

.contact_email_item {
  text-decoration: none;
  color: var(--color-primary);
  font-size: 1rem;
  font-weight: 600;
  display: inline-block;
  margin-left: 2.5rem;
  transition: all 0.4s ease;
}

.contact_email_item:hover {
  color: var(--color-white);
}

.contact p {
  font-size: 1.1rem !important;
  text-transform: initial;
  margin-top: 2rem;
  font-weight: 500;
}

.contact i {
  width: 2rem;
  font-size: 1.1rem;
  margin-right: 0.5rem;
}

.contact_each,
.resume_section {
  display: flex;
  align-items: center;
}

.contact_each small {
  font-size: 1rem;
}

.social_follow {
  margin-top: 0.25rem !important;
  padding-left: 2.1rem;
}

.social_follow i {
  font-size: 1.3rem !important;
}

@media screen and (max-width: 54.15rem) {
}

@media screen and (max-width: 37.5rem) {
  .contact p {
    font-size: 0.8rem !important;
    margin: 2rem auto;
    margin-bottom: 0;
  }

  .contact_container {
    font-size: 1.2rem;
    padding: 1rem;
  }

  .contact {
    padding: 1rem;
  }

  .contact_detail {
    font-size: 1.2rem;
    padding: 0 2rem;
    margin-top: 1rem;
    gap: 1.5rem;
  }

  .contact_detail a,
  .contact_detail small,
  .contact_detail strong {
    font-size: 0.8rem;
  }

  .contact_detail i {
    font-size: 1rem;
  }

  /* .contact_each small {
    font-size: 0.8rem;
  }

  .contact_each a {
    font-size: 0.8rem;
  }

  .contact_email_item {
    font-size: 0.8rem;
  }

  .contact_detail strong {
    font-size: 0.8rem;
  } */

  .contact i {
    width: 2rem;
    font-size: 1rem;
  }
}
