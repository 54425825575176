#contact h3,
#about h3,
#projects h3,
#skills h3,
#services h3 {
  font-size: 3.5rem !important;
  text-align: start;
}

#contact,
#about,
#projects,
#skills,
#services {
  margin-left: 3rem;
  margin: auto;
}

@media screen and (max-width: 34.3rem) {
  #contact h3,
  #about h3,
  #projects h3,
  #skills h3,
  #services h3 {
    font-size: 3rem !important;
    text-align: start;
  }

  #contact,
  #about,
  #projects,
  #skills,
  #services {
    margin-left: 0 !important;
  }
} ;
