@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Lato:wght@400;700;900&display=swap');

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow-x: hidden !important;
  width: 100vw;
}

body {
  margin: 0;
  height: auto;
  max-width: 1440px;
  font-family: var(--font-family) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-white) !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: var(--font-secondary);
  color: var(--color-white);
}

p,
small {
  color: var(--color-white);
}

button {
  font-family: var(--font-secondary) !important;
}

/* :root {
  --color-primary: #00e4f5;
  --color-white: #f4f4f8;
  --color-bg-primary: #1d1d1d;
  --color-nav-primary: #2e2e2e;
  --color-nav-hamburger-primary: rgba(46, 46, 46, 0.9);
  --color-nav-secondary: #2e2e2e;
  --color-bg-project: rgba(0, 0, 0, 0.8);
  --color-bg-project-links: #2a2929;
  --font-family: 'Lato', sans-serif;
  --font-secondary: 'Fjalla One', sans-serif;
} */

:root {
  --color-primary: #56afff;
  --color-white: #a2d0ff;
  --color-bg-primary: #2c3748;
  --color-nav-primary: #252f3f;
  --color-nav-hamburger-primary: #2c3748;
  --color-nav-secondary: #efefef;
  --color-bg-project: #0d192c;
  --color-bg-project-links: #2a2929;
  --font-family: 'Lato', sans-serif;
  --font-secondary: 'Fjalla One', sans-serif;
}

.white-theme {
  --color-primary: #56afff;
  --color-white: #495057;
  --color-bg-primary: #fffcf7;
  --color-nav-primary: #eaeaea;
  --color-nav-hamburger-primary: #eaeaea;
  --color-nav-secondary: rgb(239, 239, 239);
  --color-bg-project: rgba(244, 244, 248, 0.95);
  --color-bg-project-links: #dbdada;
  --font-family: 'Lato', sans-serif;
  --font-secondary: 'Fjalla One', sans-serif;
}

html {
  background: #1d1d1d;
}

code {
  font-family: 'Lato', sans-serif;
}

.services_section {
  display: block !important;
  width: 100%;
  padding: 3.5rem 2rem !important;
  height: auto;
}
